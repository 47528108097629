import React from "react";
import { Order } from "./routes/Order";
import {
    Text,
    theme,
    GlobalStyle,
    ModalProvider,
    DialogProvider,
    LoadingProvider,
    GenericDialog,
    Overlay,
    Loading,
} from "@holta/ui";
import { ThemeProvider } from "styled-components";
import { useRoutes } from "raviger";
import { AppHeader } from "./components/AppHeader";
import { useAuthentication } from "./hooks/useAuthentication";
import { useLoadProducts } from "./hooks/useLoadProducts";
import { useLoadcategories } from "./hooks/useLoadCateogries";
import { useLoadProductComponents } from "./hooks/useLoadProductComponents";
import { ModalAddressContextProvider } from "./components/ModalAddress/context";
import { Toaster } from "react-hot-toast";

function App() {
    const isAuthenticated = useAuthentication();
    if (!isAuthenticated) return <div>Loading...</div>;
    return <AuthenticatedApp />;
}

const routes = {
    "/": () => <Order />,
};

function AuthenticatedApp() {
    let route = useRoutes(routes);
    useLoadProducts();
    useLoadcategories();
    useLoadProductComponents();
    return (
        <ThemeProvider
            theme={{ color: theme.colorLight, ...theme.themeMedium, maxContainerWidth: "960px" }}
        >
            <ModalProvider>
                <DialogProvider>
                    <LoadingProvider>
                        <ModalAddressContextProvider>
                            <GlobalStyle />
                            <AppHeader />
                            {route}
                            <GenericDialog />
                            <Loading />
                            <Overlay />
                            <Toaster />
                        </ModalAddressContextProvider>
                    </LoadingProvider>
                </DialogProvider>
            </ModalProvider>
        </ThemeProvider>
    );
}

export default App;
